import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DropDownUl = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  z-index: 1021;
`;

const DropDownLi = styled.li`
  cursor: pointer;
  text-align: center;
  color: ${(props) => (props.active ? "#9205ea" : "#222")};
  padding: 0.5rem 1rem;
  &:hover {
    color: #9205ea;
  }
`;

function LanguageDropDown(props) {
  const { i18n } = useTranslation(["header"]);

  return (
    <DropDownUl>
      {Object.entries(i18n.store.data).map(([language, data]) => {
        return (
          <DropDownLi
            key={language}
            active={i18n.language === language}
            onClick={() => {
              i18n.changeLanguage(language);
              props.setShowDropDown(false);
            }}
          >
            {data.header.btn.language}
          </DropDownLi>
        );
      })}
    </DropDownUl>
  );
}
export default LanguageDropDown;
