import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainSlice } from "../../../slices/mainSlice";
import { useTranslation } from "react-i18next";

export const useSearchByMapBoundsBtn = (getMapBounds) => {
    const { t, i18n } = useTranslation(["main"])
    const dispatch = useDispatch();
    const [isShow, setIsShow] = useState(false);
    const mapBounds = useSelector((state) => state.main.mapBounds);

    const searchByMapBoundsBtn = useMemo(() => {
        const controlUI = document.createElement("div");
        controlUI.classList.add("storeSearchByCurrentLocationBtn");
        controlUI.title = t("searchByMapBoundBtn");
        controlUI.innerText = t("searchByMapBoundBtn");

        controlUI.addEventListener("click", () => {
            dispatch(mainSlice.actions.changeMapBounds(getMapBounds()));

        });
        return controlUI;
    }, [i18n.language])

    const setActive = () => {
        if (isShow) {
            searchByMapBoundsBtn.classList.add("show");
        } else {
            searchByMapBoundsBtn.classList.remove("show");
        }
    };
    useEffect(() => {
        setActive();
    }, [isShow, i18n.language])

    useEffect(() => {
        if (mapBounds) setIsShow(false);
    }, [mapBounds]);

    return { searchByMapBoundsBtn, setIsShow };
}