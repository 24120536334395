import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useGoogleMap } from "../../../hooks/useGoogleMap";
import { useTranslation } from "react-i18next";

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 60px;
  width: 100%;
  height: 100%;
`;
const BottomSheet = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: ${(props) => (props.placesEmpty ? "70px" : "40vh")};
  transition: height 0.4s;
  border-radius: 15px 15px 0 0;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
`;

const OkBtnWrap = styled.div`
  width: 100%;
  padding-top: 10px;
  display: flex;
`;
const OkBtn = styled.button`
  height: 40px;
  width: 100%;
  color: white;
  background-color: #8a05f1;
  border: 0;
  &:hover {
    background-color: #a93bff;
  }
`;
const CloseBtn = styled.button`
  height: 40px;
  width: ${(props) => (props.selectedPlace === null ? " 100%" : "")};
  color: #8a05f1;
  background-color: white;
  border: 0.5px #8a05f1 solid;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    background-color: #8a05f1;
    color: white;
  }
`;
const PlacesWrap = styled.div`
  overflow: auto;
  height: 100%;
`;
const PlaceBox = styled.div`
  padding: 8px 8px 8px 8px;
  border: 1px solid ${(props) => (props.isSelected ? "black" : "#ccc")};
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;
const PlaceName = styled.div`
  font-weight: bold;
`;
const SearchWrap = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 3;
  width: 100%;
  padding: 8px 8px 8px 8px;
`;
const SearchBoard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid #ccc;
`;
const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  margin-left: 10px;
  border: 0;
`;
const SearchImg = styled.img`
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
const Map = styled.div`
  height: ${(props) => (props.placesEmpty ? "100%" : "calc(100% - 40vh)")};
`;
const SearchAddressByGoogleMap = (props) => {
  const { t } = useTranslation("store");
  const DEFAULT_CENTER = { lat: 37.4859826, lng: 127.1197497 }; //문정동;
  const [places, setPlaces] = useState([]); // [{ data, marker}]
  const [selectedPlace, setSelectedPlace] = useState(null); // { data, marker}
  const searchInputRef = useRef(null);

  const { map, placesService } = useGoogleMap({
    initMapId: "map",
    options: {
      center: DEFAULT_CENTER,
      zoom: 14,
      restriction: {
        latLngBounds: {
          north: 90,
          south: -90,
          west: -179,
          east: 180,
        },
        strictBounds: true,
      },
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
    },
  });

  const createMarker = (place) => {
    const marker = new window.google.maps.Marker({
      position: place.geometry.location,
      icon: {
        url: "/img/icon/map-marker-blue.svg",
        labelOrigin: new window.google.maps.Point(10, -10),
      },
      label: { text: place.name, className: "marker-label" },
    });
    marker.setMap(map);
    marker.addListener("click", (e) => {
      setSelectedPlace({
        data: place,
        marker,
      });
    });
    return marker;
  };
  const removePlacesMarker = () => {
    for (var i = 0; i < places.length; i++) {
      const marker = places[i].marker;
      marker.setMap(null);
    }
  };

  const textSearch = (query) => {
    const request = {
      query,
    };
    placesService.textSearch(request, function (results, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        removePlacesMarker();

        const newPlaces = [];
        const bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < results.length; i++) {
          const data = results[i];
          const marker = createMarker(data);
          bounds.extend(data.geometry.location);
          newPlaces.push({
            data,
            marker,
          });
        }
        map.fitBounds(bounds);
        setPlaces(newPlaces);
      }
    });
  };
  const setGeocode = async (placeId) => {
    try {
      const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_GEO_API_KEY;

      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_API_KEY}&language=en`
      );
      const { results, status } = await res.json();
      if (status !== "OK") throw new Error(`status: ${status}`);

      const { short_name } = results[0].address_components.find((item) =>
        item.types.includes("country")
      );

      if (
        !short_name ||
        !results[0].formatted_address ||
        !results[0].geometry.location
      ) {
        throw new Error("invalid data");
      }

      props.setAddress(results[0].formatted_address);
      props.setCoordinate(results[0].geometry.location);
      props.setCountry(short_name);
      props.setIsOnSearchAddress(false);
    } catch (error) {
      console.log(error);
      alert(t("join.searchAddress.failed"));
    }
  };

  useEffect(() => {
    if (selectedPlace !== null) {
      for (var i = 0; i < places.length; i++) {
        const marker = places[i].marker;
        marker.setIcon({
          url: "/img/icon/map-marker-blue.svg",
          labelOrigin: new window.google.maps.Point(10, -10),
        });
      }
      selectedPlace.marker.setIcon({
        url: "/img/icon/map-marker-highlight.svg",
        labelOrigin: new window.google.maps.Point(10, -10),
      });
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(selectedPlace.data.geometry.location);
      map.setZoom(17);
      map.setCenter(selectedPlace.data.geometry.location);
      //   map.fitBounds(bounds);
    }
  }, [selectedPlace]);

  return (
    <Wrap>
      <SearchWrap>
        <SearchBoard>
          <SearchInput
            ref={searchInputRef}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                textSearch(e.target.value);
              }
            }}
            placeholder={t("join.searchAddress.placeholder")}
          />
          <SearchImg
            src="/img/icon/search.svg"
            alt="search"
            onClick={() => textSearch(searchInputRef.current.value)}
          />
        </SearchBoard>
      </SearchWrap>
      <Map id="map" placesEmpty={places.length === 0} />
      <BottomSheet placesEmpty={places.length === 0}>
        <PlacesWrap>
          {places.map((place) => (
            <PlaceBox
              key={place.data.place_id}
              isSelected={place.data.place_id === selectedPlace?.data.place_id}
              onClick={() => setSelectedPlace(place)}
            >
              <PlaceName>{place.data.name}</PlaceName>
              <div>{place.data.formatted_address}</div>
            </PlaceBox>
          ))}
        </PlacesWrap>
        <OkBtnWrap>
          <CloseBtn
            type="button"
            selectedPlace={selectedPlace}
            onClick={() => props.setIsOnSearchAddress(false)}
          >
            {t("btns.close", { ns: "commons" })}
          </CloseBtn>
          {selectedPlace && (
            <OkBtn
              type="button"
              onClick={() => {
                setGeocode(selectedPlace.data.place_id);
              }}
            >
              {t("join.searchAddress.useSelectedAddress")}
            </OkBtn>
          )}
        </OkBtnWrap>
      </BottomSheet>
    </Wrap>
  );
};
export default SearchAddressByGoogleMap;
