import React from "react";

function Footer() {
  return (
    <footer className="text-secondary border-top" style={{ fontSize: ".8rem" }}>
      <div className="container-max-1200 px-3 py-4">
        <span>MST Research PTE, LTD.</span>
        <address>20 CECIL STREET #05-03 PLUS, Singapore ZIP 049705</address>
        <p>E-mail: sing@x-widget.org</p>
        <div>
          COPYRIGHTⓒStoken PROJECT. ALL RIGHTS RESERVED.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
