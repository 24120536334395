import { useState, useEffect } from "react";
import { useInput } from "../../../hooks/useInput";
import { checkReferralCode } from "../../../helpers/checkReferralCode";
import { useCheckBox } from "../../../hooks/useCheckbox";
import JoinDealerStepOne from "./JoinDealerStepOne";
import JoinDealerStepTwo from "./JoinDealerStepTwo";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loadUserData } from "../../../helpers/loadUserData";
import { useTranslation } from "react-i18next";

function JoinDealer(props) {
  const { t } = useTranslation("dealer");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);

  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(false);
  const onStepClassName = (stepNum) => {
    const on = step === stepNum ? "on" : "";
    return `${on} w-50`;
  };

  const agreePrivacy = useCheckBox("agreePrivacy");
  const agreeAgeOverFourTeen = useCheckBox("agreeAgeOverFourTeen");
  const agreeEvent = useCheckBox("agreeEvent");
  const isMember = userData.groups.includes("ROLE_MEMBER");
  const name = useInput({
    name: "name",
    validate: {
      required: true,
      minLength: 1,
    },
    disabled: isMember,
  });
  const masterbankWalletAddr = useInput({
    name: "masterbankWalletAddr",
    validate: {
      required: true,
      isEtherAddress: true,
    },
    disabled: isMember,
  });
  const mobile = useInput({
    name: "mobile",
    validate: {
      required: true,
      minLength: 9,
      onlyNumber: true,
    },
    disabled: isMember,
  });
  const referralCode = useInput({
    name: "referralCode",
  });
  useEffect(() => {
    if (isMember) {
      mobile.set(userData.mobile?.replace(/[^0-9]/g, "") ?? "");
      name.set(userData?.name ?? "");
      masterbankWalletAddr.set(userData.masterbankWalletAddr);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);
    const form = document.getElementById("dealerForm");
    if (name.error.status) {
      name.validator(name.attr.value);
      form.name.focus();
      setProgress(false);

      return false;
    }
    if (masterbankWalletAddr.error.status) {
      masterbankWalletAddr.validator(masterbankWalletAddr.attr.value);
      form.masterbankWalletAddr.focus();
      setProgress(false);

      return false;
    }
    if (mobile.error.status) {
      mobile.validator(mobile.attr.value);
      form.mobile.focus();
      setProgress(false);

      return false;
    }

    if (
      referralCode.attr.value !== "" &&
      !(await checkReferralCode(referralCode.attr.value))
    ) {
      referralCode.setError({
        status: true,
        msg: t("errors.invalidReferralCode", { ns: "commons" }),
      });
      setProgress(false);

      return false;
    }

    const data = {
      // agreeWithPrivacyPolicy: agreePrivacy.attr.checked,
      agreeWithPrivacyPolicy: false,
      agreeWithAgeOverFourTeenPolicy: agreeAgeOverFourTeen.attr.checked,
      agreeWithEventSubscription: agreeEvent.attr.checked,
      name: name.attr.value,
      mobile: mobile.attr.value.replace(
        /^(\d{2,3})(\d{3,4})(\d{4})$/,
        `$1-$2-$3`
      ),
      masterbankWalletAddr: masterbankWalletAddr.attr.value,
      referralCode: referralCode.attr.value,
      memberType: "DEALER",
      userId: userData.id,
    };
    try {
      const res = await axios.post(`/members`, data);
      if (res.data.id) {
        await loadUserData(dispatch);
        props.history.push("/holding-dealer");
      }
    } catch (error) {
      const addressAlert =
        error.response?.status === 400 && error.response.data?.errorKey
          ? error.response.data.errorKey
          : null;

      const msg =
        addressAlert === "invalid_walletaddress"
          ? t("errors.invalidWalletaddress", { ns: "commons" })
          : addressAlert === "duplicate_walletaddress"
          ? t("errors.duplicateWalletaddress", { ns: "commons" })
          : addressAlert === "blank_walletaddress"
          ? t("errors.blankWalletaddress", { ns: "commons" })
          : t("errors.failed", { ns: "commons" });
      alert(msg);
      console.log(error);
    }

    setProgress(false);
  };

  return (
    <div className="container-max-640 py-3">
      <form id="dealerForm" className={"card-form"}>
        <h2 className={"text-center mb-4"}>{t("join.title")}</h2>
        <p className={"text-center"}>{t("join.text")}</p>
        <div className="joinStep">
          <ul>
            <li className={onStepClassName(0)}>
              <span className="step">STEP 01</span>
              {t("join.step-1.title")}
            </li>
            <li className={onStepClassName(1)}>
              <span className="step">STEP 02</span>
              {t("join.step-2.title")}
            </li>
          </ul>
        </div>
        {step === 0 ? (
          <JoinDealerStepOne
            setStep={setStep}
            agreePrivacy={agreePrivacy}
            agreeAgeOverFourTeen={agreeAgeOverFourTeen}
            agreeEvent={agreeEvent}
          />
        ) : (
          <JoinDealerStepTwo
            name={name}
            masterbankWalletAddr={masterbankWalletAddr}
            mobile={mobile}
            referralCode={referralCode}
            progress={progress}
            handleSubmit={handleSubmit}
          />
        )}
      </form>
    </div>
  );
}

export default JoinDealer;
