import { useState } from 'react';

export function useCheckBox(name) {
    const [value, setValue] = useState(false);

    const onChange = () => {
        setValue(!value)
    }

    return {
        attr: {
            name,
            onChange,
            checked: value
        },
        setValue
    }
}