import { createSlice } from '@reduxjs/toolkit';

export const mainSlice = createSlice({
    name: 'main',
    initialState: { selectedStore: null, selectedKind: "전체", mapBounds: null, errors: null },
    reducers: {
        selectStore: (state, action) => {
            state.selectedStore = { store: action.payload, runMoveAndZoom: false };
        },
        selectStoreForList: (state, action) => {
            state.selectedStore = { store: action.payload, runMoveAndZoom: true };
        },
        selectKind: (state, action) => {
            state.selectedKind = action.payload;
        },
        changeMapBounds: (state, action) => {
            state.mapBounds = action.payload;
        },
        setShareUrlTarget: (state, action) => {
            state.shareUrlTarget = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
    }
})
