import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORE_KINDS } from "../../../constant/storeKinds";
import { mainSlice } from "../../../slices/mainSlice";
import { useTranslation } from "react-i18next";

export const useSelectKinBtns = () => {
    const { t, i18n } = useTranslation(['commons']);
    const dispatch = useDispatch();
    const selectedKind = useSelector((state) => state.main.selectedKind);

    const selectKindBtn = (kind) => {
        const controlUI = document.createElement("div");
        controlUI.classList.add("selectKindBtn");

        controlUI.innerText = t(`storeKinds.${kind}`);
        controlUI.setAttribute("kind", kind);
        controlUI.addEventListener("click", () => {
            dispatch(mainSlice.actions.selectKind(kind));
        });
        return controlUI;
    };

    const setKindActive = (kind) => {
        if (!selectKindsEle.children) return;
        for (const child of selectKindsEle.children) {

            child.classList.remove("active");
            if (child.getAttribute("kind") === kind) {
                child.classList.add("active");
            }
        }
    };

    const selectKindsEle = useMemo(() => {
        const selectKindWrap = document.createElement("div");
        selectKindWrap.classList.add("selectKindWrap");
        selectKindWrap.appendChild(
            selectKindBtn("전체")
        );
        STORE_KINDS.forEach((kind) => {
            selectKindWrap.appendChild(selectKindBtn(kind));
        });

        return selectKindWrap;
    }, [i18n.language])


    useEffect(() => {
        setKindActive(selectedKind);
    }, [selectedKind, i18n.language])


    return selectKindsEle;
}

