import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../Footer/Footer";

function ServiceIntro() {
  const { t } = useTranslation(["serviceIntro"]);

  return (
    <>
      <main className="main-page">
        <div className="main-visual">
          <div className="inner">
            <div className="text">
              <h2>
                {t("mainVisual.title-1")}
                <br /> {t("mainVisual.title-2")}
              </h2>
              <p>
                <span>{t("mainVisual.content-1")} </span>
                {t("mainVisual.content-2")}
              </p>
              <div className="franchisee-btn">
                <Link to="/">{t("mainVisual.storeBtn")}</Link>
              </div>
            </div>
          </div>
          <div className="main-img">
            <img src="./img/main_visual_3d.png" alt="" />
          </div>
        </div>

        <div className="figures">
          <div className="inner">
            <div className="text">
              <div>
                <p className="left">
                  {t("figures.userCount-1")}
                  <br />
                  {t("figures.userCount-2")}
                </p>
                <p className="right">15 K</p>
              </div>
              <div>
                <p className="left">
                  {t("figures.storeCount-1")}
                  <br />
                  {t("figures.storeCount-2")}
                </p>
                <p className="right">2,452</p>
              </div>
              <div>
                <p className="left">
                  {t("figures.discountAmount-1")}
                  <br />
                  {t("figures.discountAmount-2")}
                </p>
                <p className="right">$ 382 K</p>
              </div>
            </div>
          </div>
        </div>

        <div className="point">
          <div className="inner">
            <div className="map">
              <div className="text">
                {t("point.title-1")}
                <br />
                {t("point.title-2")} <span>{t("point.title-highlight")}</span>
                {t("point.title-3")}
              </div>
              <div className="map-img">
                <img src="./img/phone.png" alt="" className="pc" />
                <img src="./img/phone_mb.png" alt="" className="mb" />
              </div>
            </div>

            <div className="point-text">
              <div className="text1">
                <span>{t("point.content-1")}</span>
                <span>{t("point.content-downText")}</span>
                {t("point.content-2")}
              </div>
              <div className="text2">
                {t("point.content-3")}
                <span className="dot">{t("point.content-dot-1")}</span>
                <span className="dot">{t("point.content-dot-2")}</span>
                <span className="dot">{t("point.content-dot-3")}</span>
              </div>
              <div className="text3">{t("point.content-4")}</div>
            </div>
          </div>
        </div>

        <div className="main-info">
          <div className="inner">
            <div className="con con1">
              <div className="img">
                <img src="./img/con1.png" alt="" />
              </div>
              <div className="text">
                <h3>
                  <span>{t("mianInfo.con-1.title-1")}</span>
                  {t("mianInfo.con-1.title-2")}
                  <br />
                  {t("mianInfo.con-1.title-3")}
                </h3>
                <p>{t("mianInfo.con-1.content")}</p>
              </div>
            </div>

            <div className="con con2">
              <div className="img">
                <img src="./img/con2.png" alt="" />
              </div>
              <div className="text">
                <h3>
                  <span>{t("mianInfo.con-2.title-1")}</span>
                  {t("mianInfo.con-2.title-2")}
                  <br />
                  {t("mianInfo.con-2.title-3")}
                </h3>
                <p>{t("mianInfo.con-2.content")}</p>
              </div>
            </div>

            <div className="con con3">
              <div className="img">
                <img src="./img/con3.png" alt="" />
              </div>
              <div className="text">
                <h3>
                  {t("mianInfo.con-3.title-1")}
                  <br />
                  <span>{t("mianInfo.con-3.title-2")}</span>
                  {t("mianInfo.con-3.title-3")}
                </h3>
                <p>{t("mianInfo.con-3.content")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="masterbank">
          <div className="dim"></div>
          <div className="slide-wrap">
            <div className="slide">
              <div className="slide-img img1"></div>
              <div className="slide-img img2"></div>
            </div>
          </div>

          <div className="inner">
            <div className="text">
              <h2>
                {t("masterbank.title-1")}
                <br />
                {t("masterbank.title-2")}
              </h2>
              <p>{t("masterbank.content")}</p>
              <div className="master-btn">
                <a
                  href="https://play.google.com/store/apps/details?id=io.masterbank.app&hl=ko&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  className="google-play"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Google Play
                </a>
                <a
                  href="https://apps.apple.com/us/app/masterbank/id1599645061?itsct=apps_box_badge&amp;itscg=30200"
                  className="app-store"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  App Store
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ServiceIntro;
