import { useEffect, useRef, useState } from "react";

export const useGoogleMap = ({ initMapId, options }) => {

    const [mapInitialized, setMapInitialized] = useState(false)
    const map = useRef(null);
    const placesService = useRef(null);

    const getMapBounds = () => {
        const eastLng = map.current.getBounds().getNorthEast().lng();
        const westLng = map.current.getBounds().getSouthWest().lng();
        const northLat = map.current.getBounds().getNorthEast().lat();
        const southLat = map.current.getBounds().getSouthWest().lat();
        const lngRadius =
            eastLng > westLng ? eastLng - westLng : 360 + (eastLng - westLng);
        const latRadius = northLat - southLat;

        return {
            center: map.current.getCenter().toJSON(),
            bounds: [
                map.current.getBounds().getNorthEast().toJSON(),
                map.current.getBounds().getSouthWest().toJSON(),
            ],
            radius: lngRadius < latRadius ? latRadius : lngRadius,
        };
    };

    useEffect(() => {
        map.current = new window.google.maps.Map(document.getElementById(initMapId), options);
        placesService.current = new window.google.maps.places.PlacesService(map.current);
        setMapInitialized(true);
    }, [])

    return {
        mapInitialized,
        map: map.current, placesService: placesService.current,
        getMapBounds
    }
}