import { useState, useEffect } from "react";
import { useInput } from "../../hooks/useInput";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { STORE_KINDS } from "../../constant/storeKinds";

function DetailStore(props) {
  const { t } = useTranslation("store");
  const userData = useSelector((state) => state.auth.userData);

  const [store, setStore] = useState({ tags: [] });
  const [modify, setModify] = useState(false);
  // const [modifyDiscountRate, setModifyDiscountRate] = useState(false);
  // const [modifyKind, setModifyKind] = useState(false);
  const [progress, setProgress] = useState(false);
  const discountRate = useInput({
    name: "discountRate",
    validate: {
      required: true,
      onlyNumber: true,
      minNumValue: 5,
      maxNumValue: 100,
    },
  });

  const kind = useInput({
    name: "kind",
    validate: {
      required: true,
    },
  });

  const website = useInput({
    name: "website",
    validate: {
      regexTest:
        /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/,
    },
  });

  const [tags, setTags] = useState([]);
  const pushTag = (tag) => {
    if (tags.length >= 20) {
      alert(
        t("tagMaxCount", {
          ns: "inputValidation",
          count: 20,
        })
      );
      return;
    }
    if (tag === "") {
      return;
    }
    if (tags.includes(tag)) {
      alert(
        t("duplicateTag", {
          ns: "inputValidation",
        })
      );
      return;
    }
    const newTags = [...tags];
    newTags.push(tag);
    setTags(newTags);
  };
  const removeTag = (tag) => {
    const newTags = [...tags];
    const index = newTags.indexOf(tag);
    if (index > -1) {
      newTags.splice(index, 1);
    }
    setTags(newTags);
  };

  useEffect(() => {
    axios.get(`/approved-stores/${props.match.params.storeId}`).then((res) => {
      if (res.status === 200) {
        setStore(res.data);
        discountRate.set(res.data.discountRate);
        kind.set(res.data.kind);
        website.set(res.data.website);
        setTags(res.data.tags);
      }
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);
    const form = document.getElementById("storeForm");

    if (discountRate.error.status) {
      discountRate.validator(discountRate.attr.value);
      form.discountRate.focus();
      setProgress(false);
      return false;
    }

    if (kind.error.status) {
      kind.validator(kind.attr.value);
      form.kind.focus();
      setProgress(false);
      return false;
    }

    if (website.error.status) {
      website.validator(website.attr.value);
      form.website.focus();
      setProgress(false);
      return false;
    }
    const data = {
      id: props.match.params.storeId,
      userId: userData.id,
      discountRate: discountRate.attr.value,
      kind: kind.attr.value,
      website: website.attr.value,
      tags,
    };

    axios
      .patch(`/approved-stores/${props.match.params.storeId}`, data)
      .then((res) => {
        if (res.data.id) {
          alert(t("edit.editCompleteMsg"));
          props.history.push("/stores");
        }
      })
      .catch((e) => {
        alert(t("edit.editFailedMsg"));
        setProgress(false);
        console.log(e);
      });
  };

  return (
    <div className="container-max-640 py-3">
      <h2 className="text-center mb-4">{t("edit.title")}</h2>
      <section>
        <form id="storeForm" onSubmit={handleSubmit}>
          <ul className="list-group list-group-flush ">
            <li className="list-group-item">
              <div className="row">
                <label className="col-sm-3 col-form-label text-black-50">
                  {t("labels.storeName", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    readonly
                    value={store.name}
                  />
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label className="col-sm-3 col-form-label text-black-50">
                  {t("labels.storeAddress", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    readonly
                    value={`${store.address} ${store.addressEtc}`}
                  />
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label for="discountRate" className="col-sm-3 col-form-label text-black-50">
                  {t("labels.discountRate", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    className={`form-control ${
                      discountRate.error.status && "is-invalid"
                    }`}
                    id="discountRate"
                    type="number"
                    {...discountRate.attr}
                    disabled={!modify}
                  />
                  <div className="invalid-feedback">
                    {discountRate.error.msg}
                  </div>
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label className="col-sm-3 col-form-label text-black-50">
                  {t("labels.storePhone", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    readonly
                    value={store.phone}
                  />
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label className="col-sm-3 col-form-label text-black-50">
                  {t("labels.storeOwnerPhone", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    readonly
                    value={userData.mobile}
                  />
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label for="website" className="col-sm-3 col-form-label text-black-50">
                  {t("labels.storePromotionalSite", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    className={`form-control ${
                      website.error.status && "is-invalid"
                    }`}
                    id="website"
                    type="text"
                    {...website.attr}
                    disabled={!modify}
                  />
                  <div className="invalid-feedback">{website.error.msg}</div>
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label className="col-sm-3 col-form-label text-black-50">
                  {t("labels.referralCode", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    readonly
                    value={store.referralCode}
                  />
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label for="website" className="col-sm-3 col-form-label text-black-50">
                  {t("labels.storeKind", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <select
                    className={`form-select ${
                      kind.error.status && "is-invalid"
                    }`}
                    {...kind.attr}
                    disabled={!modify}
                  >
                    <option value="">
                      {t("labels.select", { ns: "commons" })}
                    </option>
                    {STORE_KINDS.map((kind) => (
                      <option key={kind} value={kind}>
                        {t(`storeKinds.${kind}`, { ns: "commons" })}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">{kind.error.msg}</div>
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label for="website" className="col-sm-3 col-form-label text-black-50">
                  {t("labels.tag", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <div className="input-group mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="tagInput"
                      disabled={!modify}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          pushTag(e.target.value.replace(/ /g, ""));
                          e.target.value = "";
                        }
                      }}
                    />
                    <button
                      className="btn btn-outline-dark"
                      type="button"
                      disabled={!modify}
                      onClick={() => {
                        const tagInput = document.getElementById("tagInput");
                        pushTag(tagInput.value.replace(/ /g, ""));
                        tagInput.value = "";
                      }}
                    >
                      {t("btns.input", { ns: "commons" })}
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-2 d-flex flex-wrap">
                {tags.map((tag, index) => (
                  <div className="p-1 bg-light m-1 rounded" key={index}>
                    {`#${tag}`}
                    <span
                      className="p-1"
                      onClick={() => removeTag(tag)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/img/icon/close.svg`}
                        alt=""
                      />
                    </span>
                  </div>
                ))}
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <label className="col-sm-3 col-form-label text-black-50">
                  {t("labels.storeRegistrationDate", { ns: "commons" })}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control-plaintext"
                    readonly
                    value={moment(store.createdAt).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </li>
          </ul>
          <div className={"d-flex mt-5"}>
            {!modify && (
              <button
                type="button"
                className="w-100 btn btn-primary"
                onClick={() => setModify(true)}
              >
                {t("btns.edit", { ns: "commons" })}
              </button>
            )}
            {modify && (
              <>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100 me-2"
                  onClick={() => {
                    discountRate.set(store.discountRate);
                    kind.set(store.kind);
                    website.set(store.website);
                    setTags(store.tags);
                    setModify(false);
                  }}
                >
                  {t("btns.cancel", { ns: "commons" })}
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className={"w-100 btn btn-primary w-100"}
                  disabled={progress}
                >
                  {t("btns.save", { ns: "commons" })}
                </button>
              </>
            )}
          </div>
        </form>
      </section>
    </div>
  );
}

export default DetailStore;
