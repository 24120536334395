import { useState } from 'react';
import { ethers } from "ethers";
import { useTranslation } from "react-i18next";

export function useInput({ name, defaultVal = '', validate = {}, disabled = false }) {
  const { t } = useTranslation("inputValidation");
  const [value, setValue] = useState(defaultVal);
  const [error, setError] = useState({
    status: validate.required ? validate.required : false,
    msg: '',
  });

  const valFuncs = {
    required: (value, bool) => {
      if (!bool) {
        return {
          validation: true
        };
      }
      return {
        validation: value !== '',
        msg: t("required")
      }
    },
    minLength: (value, length) => {
      return {
        validation: value.length >= length,
        msg: t("minLength", { length })
      }
    },
    maxLength: (value, length) => {
      return {
        validation: value.length <= length,
        msg: t("maxLength", { length })
      }
    },
    onlyNumber: (value, bool) => {
      if (!bool) {
        return {
          validation: true
        };
      }
      return {
        validation: !isNaN(value),
        msg: t("onlyNumber")
      }
    },
    minNumValue: (value, min) => {
      return {
        validation: parseInt(value) >= min,
        msg: t("minNumValue", { min })
      }
    },
    maxNumValue: (value, max) => {
      return {
        validation: parseInt(value) <= max,
        msg: t("maxNumValue", { max })
      }
    },
    regexTest: (value, regex) => {
      if (value === "") {
        return {
          validation: true
        };
      }
      return {
        validation: regex.test(value),
        msg: t("regexTest")
      }
    },
    isEtherAddress: (value, bool) => {
      if (!bool) {
        return {
          validation: true
        };
      }
      return {
        validation: ethers.utils.isAddress(value),
        msg: t("isEtherAddress")
      }
    }
  }

  const validator = (val) => {
    for (const key in validate) {
      const result = valFuncs[key](val, validate[key])
      if (!result.validation) {
        setError({ ...error, status: true, msg: result.msg });
        break;
      } else {
        setError({ ...error, status: false, msg: '' });
      }
    }
  }

  const set = (value) => {
    setValue(value);
    validator(value);
  }

  const onChange = (event) => {
    set(event.target.value)
  }

  const onBlur = (event) => {
    validator(event.target.value);
  }

  return {
    attr: {
      name,
      value,
      onChange,
      onBlur,
      error: `${error.status}`,
      disabled
    },
    error,
    set,
    validator,
    setError
  }
}