export const getStoreWebsiteUrl = (store) => {
    const regex =
      /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/;
    let storeUrl = regex.test(store.website)
      ? store.website
      : `https://google.com/search?q=${store.name}`;

    return storeUrl.search(/^(((http(s?))\:\/\/))/) > -1
      ? storeUrl
      : `http://${storeUrl}`;
  };