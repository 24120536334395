import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mainSlice } from "../slices/mainSlice";

function ShareUrlRoute(props) {
  const dispatch = useDispatch();

  const getParams = () => {
    const storeBase64 = props.computedMatch.params.store;
    const storeParamsStr = window.atob(storeBase64);
    const storeParams = new URLSearchParams(storeParamsStr);

    return {
      storeId: parseInt(storeParams.get("id")),
      storeLatLng: {
        lat: parseFloat(storeParams.get("lat")),
        lng: parseFloat(storeParams.get("lng")),
      },
    };
  };

  dispatch(mainSlice.actions.setShareUrlTarget(getParams()));

  return <Redirect to="/" />;
}

export default ShareUrlRoute;
