import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function JoinDealerStepOne(props) {
  const { t } = useTranslation(["dealer","commons"]);
  const [agreeAll, setAgreeAll] = useState(false);
  const [error, setError] = useState(false);
  const onAgreeAll = () => {
    props.agreePrivacy.setValue(!agreeAll);
    props.agreeAgeOverFourTeen.setValue(!agreeAll);
    props.agreeEvent.setValue(!agreeAll);
  };
  const goToNext = () => {
    // if(!props.agreePrivacy.attr.checked || !props.agreeAgeOverFourTeen.attr.checked){
    if (!props.agreeAgeOverFourTeen.attr.checked) {
      setError(true);
      return;
    }
    props.setStep(1);
  };

  useEffect(() => {
    const isAllChecked = () => {
      return (
        props.agreePrivacy.attr.checked &&
        props.agreeAgeOverFourTeen.attr.checked &&
        props.agreeEvent.attr.checked
      );
    };
    setAgreeAll(isAllChecked());
  }, [
    props.agreeAgeOverFourTeen.attr.checked,
    props.agreeEvent.attr.checked,
    props.agreePrivacy.attr.checked,
  ]);

  return (
    <section>
      <div>
        <h5 className={"text-center mb-3"}>{t("join.step-1.header")}</h5>
        <div className={"form-check"}>
          <input
            className={"form-check-input"}
            type="checkbox"
            id="agreeAll"
            checked={agreeAll}
            onChange={onAgreeAll}
          />
          <label className="form-check-label" htmlFor="agreeAll">
            {t("join.step-1.agreeAll")}
          </label>
        </div>
        {/* <div className={"form-check"}>
          <input
            className={"form-check-input"}
            type="checkbox"
            id="agreePrivacy"
            {...props.agreePrivacy.attr}
          />
          <label className="form-check-label" htmlFor="agreePrivacy">
            개인정보 처리방침 (필수)
          </label>
        </div> */}
        <div className={"form-check"}>
          <input
            className={"form-check-input"}
            type="checkbox"
            id="agreeAgeOverFourTeen"
            {...props.agreeAgeOverFourTeen.attr}
          />
          <label className="form-check-label" htmlFor="agreeAgeOverFourTeen">
            {t("join.step-1.agreeAgeOverFourTeen")}
          </label>
        </div>
        <div className={"form-check"}>
          <input
            className={"form-check-input"}
            type="checkbox"
            id="agreeEvent"
            {...props.agreeEvent.attr}
          />
          <label className="form-check-label" htmlFor="agreeEvent">
            {t("join.step-1.agreeEventNoti")}
          </label>
        </div>
      </div>
      <div className="p-2">
        {error && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {t("required", { ns: "inputValidation" })}
          </span>
        )}
      </div>
      <button
        type="button"
        className="btn btn-primary w-100"
        onClick={goToNext}
      >
        {t("btns.next", { ns: "commons" })}
      </button>
    </section>
  );
}

export default JoinDealerStepOne;
