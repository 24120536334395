import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import styled from "styled-components";
import { useState } from "react";
import LanguageDropDown from "./LanguageDropDown";
const TopHeader = styled.div`
  background: white;
  border-bottom: 1px solid #dddddd;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 99;
  position: fixed;
`;
const Logo = styled.img`
  width: 140px;
`;

const VisibleDesktop = styled.div`
  @media (max-width: 992px) {
    display: none;
  }
`;

const VisibleMobile = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`;
const MobileBtn = styled.div`
  cursor: pointer;
  padding: 8px;
`;

const LangBtnBox = styled.div`
  position: relative;
`;

const FullVeil = styled.div`
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: ${(props) => (props.isShow ? "100vh" : "0")};
  position: fixed;
  background-color: rgba(0, 0, 0, ${(props) => (props.isShow ? "0.7" : "0")});
  transition: background-color 0.3s;
`;

const NavItemWrap = styled.div`
  border-top: solid 1px #dddddd;
  overflow: scroll;
  top: 59px;
  width: 320px;
  right: ${(props) => (props.showSideBar ? "0" : "-320px")};
  z-index: 1020;
  position: fixed;
  background: #fff;
  height: calc(100vh - 59px);
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  @media (min-width: 992px) {
    overflow: inherit;
    border-top: none;
    width: auto;
    top: initial;
    height: auto;
    display: flex;
    right: initial;
    z-index: initial;
    position: initial;
    justify-content: space-between;
    align-items: center;
  }
`;
function Header(props) {
  const { t } = useTranslation(["header"]);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const logout = () => {
    const REACT_APP_KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
    const KEY_CLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
    const port = window.location.port ? `:${window.location.port}` : "";
    const REDIRECTED_HOME = `${window.location?.protocol}//${window.location.hostname}${port}`;
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const ID_TOKEN_HINT = userData.idToken;
    delete axios.defaults.headers.common["Authorization"];
    sessionStorage.removeItem("userData");
    window.location.href = `${REACT_APP_KEYCLOAK_URL}/realms/${KEY_CLOAK_REALM}/protocol/openid-connect/logout?post_logout_redirect_uri=${REDIRECTED_HOME}&id_token_hint=${ID_TOKEN_HINT}`;
  };

  return (
    <>
      <FullVeil
        isShow={showDropDown || showSideBar}
        onClick={() => {
          if (showDropDown || showSideBar) {
            console.log("click!");
            setShowDropDown(false);
            setShowSideBar(false);
          }
        }}
      />

      <TopHeader
        onClick={() => {
          if (showDropDown) setShowDropDown(false);
        }}
      >
        <div className="container-max-1200 h-100">
          <nav className="d-flex justify-content-between align-items-center h-100 mx-2">
            <Link to="/">
              <Logo src="/img/stoken_logo.png" alt="" />
            </Link>
            <VisibleMobile>
              <div className="d-flex align-items-center">
                <LangBtnBox>
                  <MobileBtn onClick={() => setShowDropDown(!showDropDown)}>
                    <img src="/img/icon/language.svg" alt="" />
                  </MobileBtn>
                  {showDropDown && (
                    <LanguageDropDown setShowDropDown={setShowDropDown} />
                  )}
                </LangBtnBox>
                {!showSideBar && (
                  <MobileBtn onClick={() => setShowSideBar(true)}>
                    <img src="/img/icon/list.svg" width={20} alt="" />
                  </MobileBtn>
                )}
                {showSideBar && (
                  <MobileBtn onClick={() => setShowSideBar(false)}>
                    <img src="/img/icon/x-lg.svg" width={20} alt="" />
                  </MobileBtn>
                )}
              </div>
            </VisibleMobile>
            <NavItemWrap showSideBar={showSideBar} className="list-unstyled">
              <li className="py-2 px-3" onClick={() => setShowSideBar(false)}>
                <Link className="nav-link" to="/service-intro">
                  {t("nav.about")}
                </Link>
              </li>
              {isLoggedIn ? (
                <>
                  <li
                    className="py-2 px-3"
                    onClick={() => setShowSideBar(false)}
                  >
                    <Link className="nav-link" to="/dealer">
                      {t("nav.dealer")}
                    </Link>
                  </li>
                  <li
                    className="py-2 px-3"
                    onClick={() => setShowSideBar(false)}
                  >
                    <Link className="nav-link" to="/stores">
                      {t("nav.store")}
                    </Link>
                  </li>
                  <li
                    className="py-2 px-3"
                    onClick={() => setShowSideBar(false)}
                  >
                    <Link className="nav-link" to="/my-info">
                      {t("nav.myInfo")}
                    </Link>
                  </li>
                  <li className="ps-3">
                    <button
                      className="btn btn-outline-primary me-2"
                      onClick={logout}
                    >
                      {t("btn.logOut")}
                    </button>
                  </li>
                </>
              ) : (
                <li className="ps-3 py-2">
                  <button
                    className="btn btn-outline-primary me-2"
                    onClick={() => props.login()}
                  >
                    {t("btn.logIn")}
                  </button>
                  <button
                    className="btn btn-outline-primary me-2"
                    onClick={() => props.register()}
                  >
                    {t("btn.signUp")}
                  </button>
                </li>
              )}
              <VisibleDesktop>
                <LangBtnBox>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => setShowDropDown(!showDropDown)}
                  >
                    {t("btn.language")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-translate"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                      <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                    </svg>
                  </button>
                  {showDropDown && (
                    <LanguageDropDown setShowDropDown={setShowDropDown} />
                  )}
                </LangBtnBox>
              </VisibleDesktop>
            </NavItemWrap>
          </nav>
        </div>
      </TopHeader>
    </>
  );
}

export default Header;
