import { useEffect, useState } from "react";

export const useExternalScript = () => {
    const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=geometry,places&v=weekly`;
    const [state, setState] = useState("loading");

    const handleScript = (e) => setState(e.type === "load" ? "ready" : "error");
    useEffect(() => {
        if (state === "loading") {
            const ele = document.createElement("script");
            ele.type = "application/javascript";
            ele.src = url;
            ele.async = true;
            document.body.appendChild(ele);
            ele.addEventListener("load", handleScript);
            ele.addEventListener("error", handleScript);
        }

    }, []);

    return state;
};