import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Pages from "../Common/Pages";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Dealer(props) {
  const { t } = useTranslation(["dealer"]);
  const userData = useSelector((state) => state.auth.userData);
  const [stores, setStores] = useState([]);
  const [page, setPageState] = useState(1);
  const rowSize = 10;
  const lastPage =
    stores.length === 0
      ? 1
      : Math.floor(stores.length / rowSize) +
        (stores.length % rowSize === 0 ? 0 : 1);
  const offset = (page - 1) * rowSize;

  const setPage = (n) => {
    if (n < 1) setPageState(1);
    else if (n > lastPage) setPageState(lastPage);
    else setPageState(n);
  };
  useEffect(() => {
    const isDealer = userData.groups.includes("ROLE_WAIT_DEALER");
    if (!isDealer) {
      props.history.push("join-dealer");
      return;
    }
    axios
      .get(
        `/approved-stores?dealerCode=${userData.dealerCode}&sort=id,asc&size=1000`
      )
      .then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          setStores(res.data?.reverse());
        }
      });
  }, []);

  return (
    <div className="container-max-640 py-3">
      <h2 className="text-center mb-4">{t("title")}</h2>
      <section className="mt-7">
        <h5 className="">{t("myStoreInfoTitle")}</h5>
        <p>{t("myStoreInfoText")}</p>
        <div>
          <ul className="list-group list-group-flush">
            {stores.slice(offset, offset + rowSize).map((store, index) => (
              <li
                key={index}
                className="list-group-item d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{store.name}</div>
                  {store.address} {store.addressEtc}
                </div>
                <span>{moment(store.createdAt).format("YYYY-MM-DD")}</span>
              </li>
            ))}

            {stores.length === 0 && (
              <li className="list-group-item text-center">
                {t("notExistStore")}
              </li>
            )}
          </ul>
        </div>
        {stores.length > rowSize && (
          <Pages
            page={page}
            lastPage={lastPage}
            setPage={setPage}
            numberOfPage={5}
          />
        )}
      </section>
    </div>
  );
}

export default Dealer;
