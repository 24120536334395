import { useEffect } from "react";
import styled from "styled-components";

const Btn = styled.div`
  cursor: pointer;
  width: 2.1rem;
  height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  border-radius: 100%;
  color: ${(props) => (props.active ? "white" : "initial")};
  background-color: ${(props) => (props.active ? "#8a05f1" : "initial")};
  ${(props) =>
    !props.active &&
    `&:hover {
    background-color: #eee;
  }`};
`;

function Pages({ page, lastPage, setPage, numberOfPage }) {
  if (typeof numberOfPage === "undefined") {
    numberOfPage = window.innerWidth <= 767 ? 5 : 10;
  }

  const offset =
    page - (page % numberOfPage === 0 ? numberOfPage : page % numberOfPage) + 1;

  const pageToPrev = () => {
    const value = page - numberOfPage;
    setPage(value > 1 ? value : 1);
  };
  const pageToNext = () => {
    const value = page + numberOfPage;
    setPage(value < lastPage ? value : lastPage);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  return (
    <div className={"d-flex align-items-center mt-4"}>
      <div className={"flex-fill"}></div>
      <div>
        <Btn onClick={() => setPage(1)}>
          <img src="/img/icon/chevron-double-left.svg" alt="" />
        </Btn>
      </div>
      <div>
        <Btn onClick={() => pageToPrev()}>
          <img src="/img/icon/chevron-left.svg" alt="" />
        </Btn>
      </div>
      {Array(numberOfPage)
        .fill(0)
        .map((_, i) => {
          const cursor = offset + i;
          return cursor > lastPage ? undefined : (
            <Btn
              key={cursor}
              active={page === cursor}
              onClick={() => setPage(cursor)}
            >
              {cursor}
            </Btn>
          );
        })}
      <div>
        <Btn onClick={() => pageToNext()}>
          <img src="/img/icon/chevron-right.svg" alt="" />
        </Btn>
      </div>
      <div>
        <Btn onClick={() => setPage(lastPage)}>
          <img src="/img/icon/chevron-double-right.svg" alt="" />
        </Btn>
      </div>
      <div className={"flex-fill"}></div>
    </div>
  );
}

export default Pages;
