import toast from "react-hot-toast";
import i18n from 'i18next';

export const copyWithToast = async (value) => {

  await navigator.clipboard.writeText(value);
  toast.success(`${i18n.t('msg.copied', { ns: "commons" })}\n${value}`, {
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
  })
};