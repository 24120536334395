import { useRef } from "react";
import { MARKER_ICON } from "../../../constant/markerIcons";

export const useMyLocationBtn = (map) => {
    const myLocationMarker = useRef(null);

    const createMyCurrLocationMarker = (coords) => {
        return new window.google.maps.Marker({
            position: coords,
            icon: {
                url: MARKER_ICON.current,
            },
        });
    };
    
    const controlUI = document.createElement("div");
    controlUI.classList.add("myCurrentLocationBtn");

    const controlImg = document.createElement("img");
    controlImg.src = "/img/icon/my_location_btn.svg";
    controlUI.appendChild(controlImg);

    controlUI.addEventListener("click", () => {
        navigator.geolocation.getCurrentPosition(
            (ev) => {
                const coords = {
                    lat: Number(ev.coords.latitude),
                    lng: Number(ev.coords.longitude),
                };
                if (!myLocationMarker.current) {
                    myLocationMarker.current = createMyCurrLocationMarker(coords);
                    myLocationMarker.current.setMap(map);
                } else myLocationMarker.current.setPosition(coords);
                map.setCenter(coords);
            },
            (err) => {
                if (err.code === 1) {
                    alert("위치 권한이 없습니다.");
                }
                console.error(err);
            }
        );
    });
    return controlUI;
};

