import axios from "axios";
import keycloak from '../keycloak'
import { authSlice } from "../slices/authSlice";

export async function loadUserData(dispatch) {
  const userDataStr = sessionStorage.getItem('userData');
  let authenticated;
  if (userDataStr !== null) {
    const userData = JSON.parse(userDataStr);
    //세션 스토리지에 userData가 저장된 상태로 페이지가 리프레쉬 되었을때
    //세션 스토리지에 userData가 저장되어 있다면 토큰 값으로 init
    authenticated = await keycloak.init({
      token: userData.token,
      refreshToken: userData.refreshToken,
      idToken: userData.idToken,
    });
  } else {
    //로그인 페이지에서 리다이렉트 되었을때
    authenticated = await keycloak.init({ checkLoginIframe: false });
  };

  if (!authenticated) return;

  const userData = await arrangeUserData();
  dispatch(authSlice.actions.logIn(userData));
}

export async function arrangeUserData() {
    const userInfoByKeycloak = await keycloak.loadUserInfo();

    let userData = {
      token: keycloak.token,
      idToken: keycloak.idToken,
      refreshToken: keycloak.refreshToken,
      groups: userInfoByKeycloak.groups,
      id: userInfoByKeycloak.sub,
      stokenWallet: userInfoByKeycloak.stoken_wallet ? userInfoByKeycloak.stoken_wallet : '',
      email: userInfoByKeycloak.email,
    }

    if (userData.groups.includes("ROLE_MEMBER")) {

      const userInfoByApi = await getUserInfoByApi(keycloak.token);
      if (userInfoByApi != null) {

        userData = {
          ...userData,
          memberID: userInfoByApi.id,
          name: userInfoByApi.name,
          mobile: userInfoByApi.mobile,
          masterbankWalletAddr: userInfoByApi.masterbankWalletAddr,
          dealerCode: userInfoByApi.dealerCode,
          referralCode: userInfoByApi.referralCode,
        }
      }
    }

    return userData;
}
const getUserInfoByApi = async (token) => {
    let res;
    try {
      res = await axios.get('/me', { headers: { Authorization: `Bearer ${token}` } });
      return res.data;
    } catch (error) {
      console.error(error)
      return null;
    }
  }
