import { useSelector } from "react-redux";
import { copyWithToast } from "../../helpers/copyWithToast";
import { useTranslation } from "react-i18next";

function MyInfo() {
  const { t } = useTranslation("myInfo");
  const userData = useSelector((state) => state.auth.userData);
  const isDealer = userData.groups.includes("ROLE_WAIT_DEALER");
  const isMember = userData.groups.includes("ROLE_MEMBER");

  return (
    <div className="container-max-640 py-3">
      <h2 className="text-center mb-4">{t("title")}</h2>
      <section>
        <ul className="list-group list-group-flush ">
          {isMember && (
            <li className="list-group-item">
              <span>{t("labels.name", { ns: "commons" })} : </span>
              <span>{userData.name}</span>
            </li>
          )}
          {isMember && (
            <li className="list-group-item">
              <span>{t("labels.phone", { ns: "commons" })} : </span>
              <span>{userData.mobile}</span>
            </li>
          )}
          <li className="list-group-item">
            <span>{t("labels.email", { ns: "commons" })} : </span>
            <span>{userData.email}</span>
          </li>
          {isDealer && (
            <li className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto ">
                <div className="fw-bold">
                  {t("labels.masterbankWalletAddress", { ns: "commons" })}
                </div>
                <span className="text-break">
                  {userData.masterbankWalletAddr}
                </span>
              </div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  copyWithToast(userData.masterbankWalletAddr);
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/clipboard-plus.svg`}
                  alt=""
                />
              </span>
            </li>
          )}
          {isDealer && (
            <li className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  {t("labels.myDealerCode", { ns: "commons" })}
                </div>
                {userData.dealerCode}
              </div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  copyWithToast(userData.dealerCode);
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/clipboard-plus.svg`}
                  alt=""
                />
              </span>
            </li>
          )}
          {isDealer && (
            <li className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold">
                  {" "}
                  {t("labels.referralCode", { ns: "commons" })}
                </div>
                {userData.referralCode}
              </div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  copyWithToast(userData.referralCode);
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/clipboard-plus.svg`}
                  alt=""
                />
              </span>
            </li>
          )}
        </ul>
      </section>
    </div>
  );
}

export default MyInfo;
