import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ko from './ko';
import en from './en';
import vi from './vi';
import bn from './bn';
import id from './id';

const translation = () => {
  return {
    en,
    ko,
    vi,
    bn,
    id
  }
};

const resources = translation();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: true,
    },
  });

export default i18n;
