import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useInput } from "../../../hooks/useInput";
import axios from "axios";
import JoinStoreStepOne from "./JoinStoreStepOne";
import JoinStoreStepTwo from "./JoinStoreStepTwo";
import JoinStoreStepThree from "./JoinStoreStepThree";
import { useDispatch } from "react-redux";
import { loadUserData } from "../../../helpers/loadUserData";
import { useTranslation } from "react-i18next";

function JoinStore(props) {
  const { t, i18n } = useTranslation("store");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const API_KEY = process.env.REACT_APP_NAVER_MAP_API_KEY;
  let ele = document.createElement("script");
  ele.type = "application/javascript";
  ele.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${API_KEY}&submodules=geocoder`;
  ele.async = true;
  document.body.appendChild(ele);
  const isMember = userData.groups.includes("ROLE_MEMBER");
  const isDealer = userData.groups.includes("ROLE_WAIT_DEALER");
  useEffect(() => {
    if (isDealer) {
      referralCode.set(userData.dealerCode);
    }

    if (isMember) {
      memberMobile.set(userData.mobile?.replace(/[^0-9]/g, "") ?? '');
      memberName.set(userData.name ?? '');
      masterbankWalletAddr.set(userData?.masterbankWalletAddr ?? '');
    }
  }, []);
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(false);

  const onStepClassName = (stepNum) => {
    const on = step === stepNum ? "on" : "";
    return `${on} w-33`;
  };
  const name = useInput({
    name: "name",
    validate: {
      required: true,
      minLength: 1,
    },
  });
  const kind = useInput({
    name: "kind",
    validate: {
      required: true,
    },
  });
  const address = useInput({
    name: "address",
    validate: {
      required: true,
    },
  });
  const addressEtc = useInput({
    name: "addressEtc",
    validate: {
      required: true,
    },
  });
  const [country, setCountry] = useState("");
  const phone = useInput({
    name: "phone",
    validate: {
      required: true,
      minLength: 9,
      onlyNumber: true,
    },
  });
  const referralCode = useInput({
    name: "referralCode",
    validate: {
      required: true,
    },
  });
  const discountRate = useInput({
    name: "discountRate",
    validate: {
      required: true,
      onlyNumber: true,
      minNumValue: 5,
      maxNumValue: 100,
    },
  });

  const memberName = useInput({
    name: "memberName",
    validate: {
      required: true,
      minLength: 1,
      maxLength: 20,
    },
    disabled: isMember,
  });
  const masterbankWalletAddr = useInput({
    name: "masterbankWalletAddr",
    validate: {
      required: true,
      isEtherAddress: true,
    },
    disabled: isMember,
  });
  const website = useInput({
    name: "website",
    validate: {
      regexTest:
        /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/,
    },
  });
  const memberMobile = useInput({
    name: "memberMobile",
    validate: {
      required: true,
      minLength: 9,
      onlyNumber: true,
    },
    disabled: isMember,
  });
  const [tags, setTags] = useState([]);

  const [coordinate, setCoordinate] = useState({});
  // const agreePrivacy = useCheckBox("agreePrivacy");
  // const agreeAgeOverFourTeen = useCheckBox("agreeAgeOverFourTeen");
  // const agreeEvent = useCheckBox("agreeEvent");
  // const [agreeError, setAgreeError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProgress(true);
    const form = document.getElementById("storeForm");
    // if (!agreePrivacy.attr.checked || !agreeAgeOverFourTeen.attr.checked) {
    //   setAgreeError(true);
    //   setProgress(false);
    //   return false;
    // }
    if (discountRate.error.status) {
      discountRate.validator(discountRate.attr.value);
      form.discountRate.focus();
      setProgress(false);

      return false;
    }
    if (website.error.status) {
      website.validator(website.attr.value);
      form.website.focus();
      setProgress(false);

      return false;
    }

    const data = {
      name: name.attr.value,
      kind: kind.attr.value,
      country,
      address: address.attr.value,
      addressEtc: addressEtc.attr.value,
      zipCode: "",
      coordinate,
      phone: phone.attr.value.replace(
        /^(\d{2,3})(\d{3,4})(\d{4})$/,
        `$1-$2-$3`
      ),
      referralCode: referralCode.attr.value,
      discountRate: discountRate.attr.value,
      memberName: memberName.attr.value,
      masterbankWalletAddr: masterbankWalletAddr.attr.value,
      website: website.attr.value,
      memberMobile: memberMobile.attr.value.replace(
        /^(\d{2,3})(\d{3,4})(\d{4})$/,
        `$1-$2-$3`
      ),
      tags,
      // agreeWithPrivacyPolicy: agreePrivacy.attr.checked,
      // agreeWithAgeOverFourTeenPolicy: agreeAgeOverFourTeen.attr.checked,
      // agreeWithEventSubscription: agreeEvent.attr.checked,
      agreeWithPrivacyPolicy: false,
      agreeWithAgeOverFourTeenPolicy: false,
      agreeWithEventSubscription: false,
    };
    try {
      const res = await axios.post(`/approved-stores`, data);
      if (res.data.id) {
        await loadUserData(dispatch);
        props.history.push("/holding-store");
      }
    } catch (error) {
      const addressAlert =
        error.response?.status === 400 && error.response.data?.errorKey
          ? error.response.data.errorKey
          : null;

      const msg =
        addressAlert === "duplicate_walletaddress"
          ? t("errors.duplicateWalletaddress", { ns: "commons" })
          : addressAlert === "blank_walletaddress"
          ? t("errors.blankWalletaddress", { ns: "commons" })
          : t("errors.failed", { ns: "commons" });
      alert(msg);
      console.log(error);
    }
    setProgress(false);
  };

  return (
    <div className="container-max-640 py-3">
      <form id="storeForm" className={"card-form"}>
        <h2 className={"text-center mb-4"}>{t("join.title")}</h2>
        <p className="text-center">{t("join.header")}</p>
        <div className="joinStep">
          <ul>
            <li className={onStepClassName(0)}>
              <span className="step">STEP 01</span>
              {t("join.step-1.title")}
            </li>
            <li className={onStepClassName(1)}>
              <span className="step">STEP 02</span>
              {t("join.step-2.title")}
            </li>
            <li className={onStepClassName(2)}>
              <span className="step">STEP 03</span>
              {t("join.step-3.title")}
            </li>
          </ul>
        </div>
        {step === 0 && (
          <JoinStoreStepOne
            name={name}
            kind={kind}
            address={address}
            addressEtc={addressEtc}
            setCountry={setCountry}
            phone={phone}
            referralCode={referralCode}
            setCoordinate={setCoordinate}
            setStep={setStep}
          />
        )}
        {step === 1 && (
          <JoinStoreStepTwo
            memberName={memberName}
            masterbankWalletAddr={masterbankWalletAddr}
            memberMobile={memberMobile}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <JoinStoreStepThree
            discountRate={discountRate}
            tags={tags}
            setTags={setTags}
            website={website}
            // agreePrivacy={agreePrivacy}
            // agreeAgeOverFourTeen={agreeAgeOverFourTeen}
            // agreeEvent={agreeEvent}
            // agreeError={agreeError}
            setStep={setStep}
            progress={progress}
            handleSubmit={handleSubmit}
          />
        )}
      </form>
    </div>
  );
}

export default JoinStore;
