import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";

export const authSlice = createSlice({
    name: 'auth',
    initialState: { userData: null, isLoggedIn: false },
    reducers: {
        logIn: (state, action) => {
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
            sessionStorage.setItem('userData', JSON.stringify(action.payload));

            state.userData = action.payload;
            state.isLoggedIn = true;
        },
    }
})