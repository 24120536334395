export const STORE_KINDS = [
    "음식점",
    "반려동물",
    "카페&디저트",
    "쇼핑",
    "뷰티",
    "숙박",
    "운동",
    "문화&공연",
    "기타",
];
