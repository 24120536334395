import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Maps from "./Maps/Maps";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getStoreWebsiteUrl } from "../../helpers/getStoreWebsiteUrl";
import Pages from "../Common/Pages";
import { ScrollToTopBtn } from "../Common/ScrollToTopBtn";
import styled from "styled-components";
import { mainSlice } from "../../slices/mainSlice";
import { getBoundsByLatLngs } from "../../helpers/getBoundsByLatLng";
import { ShareModal } from "../Common/ShareModal";
import { useGeoplugin } from "../../hooks/useGeoplugin";
import { INVALID_MAP_CENTER_VALUE } from "../../constant/errors";

const StoreContainer = styled.div`
  border-radius: 1rem;
`;
const StoreBottomBtnRow = styled.div`
  height: 40px;
  text-align: center;
`;
const StoreKind = styled.span`
  color: #ccc;
  font-size: 0.8em;
`;
const StoreName = styled.span`
  font-weight: bold;
  width: 120px;
  display: inline-block;
`;
const StoreDiscountRate = styled.span`
  text-align: end;
  color: #8a05f1;
`;
const StoreNotExist = styled.div`
  height: 100px;
  color: #aaa;
`;
const SearchForm = styled.form`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  background-color: #fff;
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

const SearchInputContainer = styled.div`
  border-radius: 30px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 10px 10px;
  width: 100%;
  max-width: 1200px;
`;
const SearchInput = styled.input`
  border: 0px;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  margin-right: 15px;
  &:focus {
    box-shadow: none;
  }
`;
const SearchImg = styled.img`
  margin-left: 10px;
  margin-right: 10px;
`;

function Main(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["main", "commons"]);
  const search = useRef("");
  const mapBounds = useSelector((state) => state.main.mapBounds);
  const [currStores, setCurrStores] = useState({
    runFitBounds: false,
    stores: [],
  });
  const initialStores = useRef(null);
  const [searchInputText, setSearchInputText] = useState("");
  const selectedStore = useSelector((state) => state.main.selectedStore?.store);
  const selectedKind = useSelector((state) => state.main.selectedKind);
  const errors = useSelector((state) => state.main.errors);

  const [page, setPage] = useState(1);
  const rowSize = 10;
  const offset = (page - 1) * rowSize;
  const lastPage =
    currStores.stores.length === 0
      ? 1
      : Math.floor(currStores.stores.length / rowSize) +
        (currStores.stores.length % rowSize === 0 ? 0 : 1);
  const [shareUrl, setShareUrl] = useState(null);
  const geoData = useGeoplugin();

  const getStoresByMapBounds = async () => {
    try {
      const res = await axios.get(`/approved-stores?sort=id,desc&size=10000`);
      const data = res.data.filter(
        (store) => store.coordinate.lat && store.coordinate.lng
      );

      if (geoData?.status === "OK") {
        data.sort((a, b) => {
          const computeDistanceBetween =
            window.google.maps.geometry.spherical.computeDistanceBetween;

          return (
            computeDistanceBetween(geoData.latLng, a.coordinate) -
            computeDistanceBetween(geoData.latLng, b.coordinate)
          );
        });
      }

      initialStores.current = data;
      setCurrStoresWithFiltering({ runFitBounds: true });
    } catch (e) {
      console.error(e);
    }
  };
  const fetchStoresByMapBounds = async (mapBounds) => {
    try {
      let mapBoundsQuery = "";
      if (mapBounds.radius <= 180) {
        mapBoundsQuery += mapBounds.center
          ? `&centerLat=${mapBounds.center.lat}&centerLng=${mapBounds.center.lng}`
          : "";

        mapBoundsQuery += mapBounds.radius ? `&radius=${mapBounds.radius}` : "";
      }

      const res = await axios.get(
        `/approved-stores?sort=id,desc&size=10000${mapBoundsQuery}`
      );

      // console.log(res.data);
      const bounds = getBoundsByLatLngs(mapBounds.bounds);
      const data = res.data
        .filter((store) => store.coordinate.lat && store.coordinate.lng)
        .filter((store) => {
          return bounds.customContains(store.coordinate);
        });
      // console.log(data);

      data.sort((a, b) => {
        const computeDistanceBetween =
          window.google.maps.geometry.spherical.computeDistanceBetween;

        return (
          computeDistanceBetween(mapBounds.center, a.coordinate) -
          computeDistanceBetween(mapBounds.center, b.coordinate)
        );
      });
      initialStores.current = data;
      setCurrStoresWithFiltering();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (mapBounds) fetchStoresByMapBounds(mapBounds);
  }, [mapBounds]);

  useEffect(() => {
    setCurrStoresWithFiltering();
  }, [selectedKind]);

  useEffect(() => {
    if (errors === INVALID_MAP_CENTER_VALUE) getStoresByMapBounds();
  }, [errors]);

  const setCurrStoresWithFiltering = ({ runFitBounds = false } = {}) => {
    if (initialStores.current && (search || selectedKind)) {
      setCurrStores({
        stores: initialStores.current.filter((store) => {
          return (
            (store.name.toLowerCase().includes(search.current.toLowerCase()) ||
              store.address
                .toLowerCase()
                .includes(search.current.toLowerCase()) ||
              (store.addressEtc !== null &&
                store.addressEtc
                  .toLowerCase()
                  .includes(search.current.toLowerCase())) ||
              store.phone.includes(search.current) ||
              store.tags
                .toString()
                .replaceAll(",", " ")
                .toLowerCase()
                .includes(search.current.toLowerCase())) &&
            (selectedKind === "전체" ? true : store.kind === selectedKind)
          );
        }),
        runFitBounds,
      });
      setPage(1);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    search.current = searchInputText;
    getStoresByMapBounds();
  };

  return (
    <>
      <SearchForm onSubmit={onSubmit}>
        <SearchInputContainer>
          <SearchImg src="/img/icon/search.svg" alt="search" />
          <SearchInput
            type="text"
            className="form-control"
            placeholder={t("searchPlaceholder")}
            value={searchInputText}
            onChange={(e) => setSearchInputText(e.target.value)}
          />
          {searchInputText && (
            <SearchImg
              src="/img/icon/search_remove.svg"
              alt="search"
              onClick={() => {
                setSearchInputText("");
                search.current = "";
                // setCurrStoresWithFiltering();
              }}
            />
          )}
        </SearchInputContainer>
      </SearchForm>
      <div className="container-max-1200">
        {geoData && (
          <Maps
            stores={currStores.stores}
            runFitBounds={currStores.runFitBounds}
            mapCenter={geoData.status === "OK" ? geoData.latLng : null}
          />
        )}
   
        {currStores.stores.slice(offset, offset + rowSize).map((store) => (
          <StoreContainer
            className={`m-3 d-flex flex-column mb-3 ${
              selectedStore && selectedStore.id === store.id
                ? "border border-dark"
                : "border"
            }`}
            key={store.id}
            id={`main-store-${store.id}`}
          >
            <div
              onClick={() => {
                dispatch(mainSlice.actions.selectStoreForList(store));
              }}
            >
              <div className="p-2 ps-3 d-flex justify-content-between">
                <div className="d-flex">
                  <StoreName className="me-2 text-truncate">
                    {store.name}
                  </StoreName>
                  <StoreKind>
                    {t(`storeKinds.${store.kind}`, { ns: "commons" })}
                  </StoreKind>
                </div>
                <StoreDiscountRate>{`${t("storeContainer.discountRate")} ${
                  store.discountRate
                }%`}</StoreDiscountRate>
              </div>
              <div className="p-1 ps-3 d-flex align-items-center">
                <img
                  className="mx-1"
                  src="/img/icon/main_store_address.svg"
                  alt=""
                />
                <span>{`${store.address ?? ""} ${
                  store.addressEtc ?? ""
                }`}</span>
              </div>
            </div>
            <div className="p-1 ps-3 d-flex align-items-center">
              <span onClick={() => window.open(`tel:${store.phone}`, "_self")}>
                <img
                  className="mx-1"
                  src="/img/icon/main_store_phone.svg"
                  alt=""
                />
                {`${store.phone ?? ""}`}
              </span>
            </div>
            <StoreBottomBtnRow className="d-flex flex-row justify-content-around align-items-center border-top mt-1">
              <div
                className="p-1 w-50 border-end"
                onClick={() => {
                  const storeParamsBase64 = window.btoa(
                    `?id=${store.id}&lat=${store.coordinate.lat}&lng=${store.coordinate.lng}`
                  );
                  const params = `target/${storeParamsBase64}`;
                  const url = `${window.location.origin}/${params}`;
                  if (navigator.share) {
                    navigator.share({
                      url: url,
                    });
                  } else {
                    setShareUrl(url);
                  }
                }}
              >
                <img
                  className="me-1"
                  src="/img/icon/main_store_share.svg"
                  alt=""
                />
                {t("storeContainer.share")}
              </div>
              <div
                className="p-1 w-50"
                onClick={() => {
                  window.open(getStoreWebsiteUrl(store));
                }}
              >
                <img
                  className="me-1"
                  src="/img/icon/main_store_link.svg"
                  alt=""
                />
                {t("storeContainer.detail")}
              </div>
            </StoreBottomBtnRow>
          </StoreContainer>
        ))}
        {currStores.stores.length > rowSize && (
          <div className="mb-4">
            <Pages page={page} lastPage={lastPage} setPage={setPage} />
          </div>
        )}
        {currStores.stores.length === 0 && (
          <StoreNotExist className="d-flex p-2 justify-content-center align-items-center">
            {t("notExistStore")}
          </StoreNotExist>
        )}
      </div>
      <ScrollToTopBtn />
      <ShareModal
        shareUrl={shareUrl}
        closeCallback={() => {
          setShareUrl(null);
        }}
      />
    </>
  );
}

export default Main;
