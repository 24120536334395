import axios from "axios";


export async function checkReferralCode(referralCode) {
    try {
        const res = await axios.get(`/members/dealers/${referralCode}`);
        if (res.status === 200)
            return true;
        else
            return false;
    } catch (error) {
        console.log(error.status)
        return false;
    }
}