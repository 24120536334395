import Keycloak from 'keycloak-js'

const KEY_CLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
const KEY_CLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
const KEY_CLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

const keycloak = new Keycloak({
  url: KEY_CLOAK_URL,
  realm: KEY_CLOAK_REALM,
  clientId: KEY_CLOAK_CLIENT_ID
 })

export default keycloak