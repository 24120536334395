import { useTranslation } from "react-i18next";

function JoinStoreStepTwo(props) {
  const { t } = useTranslation("store");
  const goToNext = async () => {
    const form = document.getElementById("storeForm");

    if (props.memberName.error.status) {
      props.memberName.validator(props.memberName.attr.value);
      form.memberName.focus();
      return false;
    }

    if (props.masterbankWalletAddr.error.status) {
      props.masterbankWalletAddr.validator(
        props.masterbankWalletAddr.attr.value
      );
      form.masterbankWalletAddr.focus();
      return false;
    }

    if (props.memberMobile.error.status) {
      props.memberMobile.validator(props.memberMobile.attr.value);
      form.memberMobile.focus();
      return false;
    }

    props.setStep(2);
  };
  return (
    <div className="info">
      <div className={"mt-3"}>
        <label>{t("labels.name", { ns: "commons" })}</label>
        <span className={"star"}>*</span>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            {...props.memberName.attr}
            placeholder={t("join.placeholder.storeOwnerName")}
          />
        </div>
        {props.memberName.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.memberName.error.msg}
          </span>
        )}
      </div>
      <div className={"mt-3"}>
        <label>{t("labels.walletAddress", { ns: "commons" })}</label>
        <span className={"star"}>*</span>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            {...props.masterbankWalletAddr.attr}
            placeholder={t("join.placeholder.walletAddress")}
          />
        </div>
        <div style={{ color: "gray", fontSize: "0.8em" }}>
          ⓘ {t("join.placeholder.walletAddress")}
        </div>
        {props.masterbankWalletAddr.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.masterbankWalletAddr.error.msg}
          </span>
        )}
      </div>
      <div className={"mt-3"}>
        <label>{t("labels.storePhone", { ns: "commons" })}</label>
        <span className={"star"}>*</span>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            {...props.memberMobile.attr}
            placeholder="01012345678"
          />
        </div>
      </div>
      {props.memberMobile.error.status && (
        <span style={{ color: "red", fontSize: "0.8em" }}>
          {props.memberMobile.error.msg}
        </span>
      )}
      <div className={"d-flex mt-5"}>
        <button
          type="button"
          className={"btn btn-secondary flex-shrink-1 me-1"}
          onClick={() => props.setStep(0)}
        >
          {t("btns.prev", { ns: "commons" })}
        </button>
        <button
          type="button"
          className={"btn btn-primary flex-fill"}
          onClick={goToNext}
        >
          {t("btns.next", { ns: "commons" })}
        </button>
      </div>
    </div>
  );
}
export default JoinStoreStepTwo;
