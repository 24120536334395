import header from './header.json'
import commons from './commons.json'
import inputValidation from './inputValidation.json'
import serviceIntro from './serviceIntro.json'
import main from './main.json'
import dealer from './dealer.json'
import store from './store.json'
import myInfo from './myInfo.json'

const en = {
    header,
    commons,
    inputValidation,
    serviceIntro,
    main,
    dealer,
    store,
    myInfo,
}
export default en
