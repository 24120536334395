import axios from "axios";
import { useEffect, useState } from "react";

export const useGeoplugin = () => {
  const GEO_API_URL = process.env.REACT_APP_GEO_API_URI;
  const [geoData, setGeoData] = useState(null);
  const getGeoData = async () => {
    try {
      const res = await axios.get(`${GEO_API_URL}`);
      if (res.data.geoplugin_latitude && res.data.geoplugin_longitude) {

        setGeoData(
          {
            status: "OK",
            latLng: {
              lat: Number(res.data.geoplugin_latitude),
              lng: Number(res.data.geoplugin_longitude),
            }
          });

      } else {
        throw Error("invalid geoplugin_latitude, geoplugin_longitude")
      }
    } catch (error) {
      console.log(error);
      setGeoData(
        {
          status: "ERROR",
          error
        });
    }
  }
  useEffect(() => {
    getGeoData();
  }, [])

  return geoData;
};