import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

function HoldingStore(prop) {
  const { t } = useTranslation("store");

  return (
    <div className="container-max-640 py-3">
      <h2 className={"text-center mb-4"}>{t("complete.title")}</h2>
      <p className={"text-center"}>{t("complete.text-1")}</p>
      <p className={"text-center"}>
        {t("complete.text-2")}
        <br />
        {t("complete.text-3")}
      </p>
      <p className={"text-center"}>{t("complete.text-4")}</p>

      <div className={"d-flex mt-5"}>
        <Link className={"btn btn-primary w-100"} to="/">
          {t("btns.ok", { ns: "commons" })}
        </Link>
        {/* <button type="submit" className={"btn btn-primary w-100"}>
            다음
          </button> */}
      </div>
    </div>
  );
}

export default HoldingStore;
