import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useGoogleMap } from "../../../hooks/useGoogleMap";
import { useMyLocationBtn } from "./useMyLocationBtn";
import { useSelectKinBtns } from "./useSelectKindBtns";
import { useSearchByMapBoundsBtn } from "./useSearchByMapBoundsBtn";
import { useGoogleMarker } from "./useGoogleMarker";
import { mainSlice } from "../../../slices/mainSlice";
import { useTranslation } from "react-i18next";
import { INVALID_MAP_CENTER_VALUE } from "../../../constant/errors";

const MapContianer = styled.div`
  height: 60vh;
  margin-top: 118px;
  max-width: 1200px;
`;

const MAP_CENTER = { lat: 35.8663, lng: 128.5928 }; //가맹점명: Re:NK 대구, 주소: 대구 중구 달구벌대로 2095;

const Maps = (props) => {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const selectedStore = useSelector((state) => state.main.selectedStore);
  const shareUrlTarget = useSelector((state) => state.main.shareUrlTarget);

  const { map, mapInitialized, getMapBounds } = useGoogleMap({
    initMapId: "map",
    options: {
      zoom: props.mapCenter ? 11 : 2,
      center:
        shareUrlTarget?.storeLatLng.lat && shareUrlTarget?.storeLatLng.lng
          ? shareUrlTarget?.storeLatLng
          : props.mapCenter ?? MAP_CENTER,
      restriction: {
        latLngBounds: {
          north: 90,
          south: -90,
          west: -179,
          east: 180,
        },
        strictBounds: true,
      },
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    },
  });

  const googleMarker = useGoogleMarker(map);
  const myLocationBtn = useMyLocationBtn(map);
  const selectKinBtns = useSelectKinBtns();
  const { searchByMapBoundsBtn, setIsShow } =
    useSearchByMapBoundsBtn(getMapBounds);

  useEffect(() => {
    if (mapInitialized) {
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        myLocationBtn
      );
      map.addListener("click", () => {
        dispatch(mainSlice.actions.selectStore(null));
      });

      const tilesloaded = map.addListener("tilesloaded", () => {
        if (props.mapCenter) {
          dispatch(mainSlice.actions.changeMapBounds(getMapBounds()));
        } else {
          dispatch(mainSlice.actions.setErrors(INVALID_MAP_CENTER_VALUE));
        }
        new window.google.maps.event.removeListener(tilesloaded);
      });

      map.addListener("bounds_changed", () => {
        setIsShow(true);
      });
    }
  }, [mapInitialized]);

  useEffect(() => {
    if (mapInitialized) {
      map.controls[window.google.maps.ControlPosition.TOP_CENTER].clear();
      map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
        selectKinBtns
      );
      map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].clear();
      map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
        searchByMapBoundsBtn
      );
    }
  }, [mapInitialized, i18n.language]);

  useEffect(() => {
    if (selectedStore?.store) {
      googleMarker.setSelectedMarker(selectedStore.store, {
        asCenter: selectedStore.runMoveAndZoom,
      });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      googleMarker.removeSelectedMarker();
    }
  }, [selectedStore]);

  useEffect(() => {
    googleMarker.setMarker(props.stores, props.runFitBounds);
  }, [props.stores, props.runFitBounds]);

  useEffect(() => {
    if (shareUrlTarget?.storeId && props.stores.length > 0) {
      const matchedStore = props.stores.find((store) => {
        return store.id === shareUrlTarget?.storeId;
      });
      dispatch(mainSlice.actions.selectStoreForList(matchedStore));
      dispatch(mainSlice.actions.setShareUrlTarget(null));
    }
  }, [props.stores]);

  return <MapContianer id="map" />;
};

export default Maps;
