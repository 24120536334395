import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

function AuthRoute({ component: Component, ...rest }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  
  return (
    <Route
      {...rest}
      render={props => 
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect 
          to="/"
            // to={{
            //   pathname: "/login",
            //   state: { from: props.location }
            // }} 
          />
        )
      } 
    />
  )
}

export default AuthRoute;