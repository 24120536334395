import { useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { MARKER_ICON } from "../../../constant/markerIcons";
import { getStoreWebsiteUrl } from "../../../helpers/getStoreWebsiteUrl";
import { MarkerClusterer, DefaultRenderer } from "@googlemaps/markerclusterer";
import { mainSlice } from "../../../slices/mainSlice";
import { getBoundsByLatLngs } from "../../../helpers/getBoundsByLatLng";
import { useTranslation } from "react-i18next";

export const useGoogleMarker = (map) => {
    const { t } = useTranslation(['commons']);
    const dispatch = useDispatch();
    const markerCluster = useRef(null);
    const storesWithMarker = useRef(null);
    const infoWindow = useMemo(() => new window.google.maps.InfoWindow(), []);

    const storesAddMarker = (stores) => {
        const markers = [];
        stores.forEach((store) => {
            const marker = createMarker(store);
            marker.addListener("click", () => {
                dispatch(mainSlice.actions.selectStore(store));
            });
            markers.push({
                store,
                marker
            });
        });

        return markers;
    }

    const createMarker = (store) => {
        return new window.google.maps.Marker({
            position: store.coordinate,
            icon: {
                url:
                    store.discountRate >= 80 ? MARKER_ICON.promotion : MARKER_ICON.normal,
                labelOrigin: new window.google.maps.Point(10, -10),
            },
            label: { text: store.name, className: "marker-label" },
        });
    };

    const setSelectedMarker = (store, { asCenter = false } = {}) => {
        const { marker } = storesWithMarker.current?.find((item) => item.store.id === store.id);
        removeSelectedMarker();
        marker.setZIndex(100);
        marker.setIcon({
            url: MARKER_ICON.seleted,
        });
        marker.setLabel(null);

        if (asCenter) {
            map.setZoom(17);
            map.setCenter(store.coordinate);
        }

        infoWindow.setContent(`
    <p style="font-size: 14px;">
      <span>${t(`storeKinds.${store.kind}`)}</span>
      <span style="color:#8A05F1">${store.discountRate}%</span>
    </p>
    <a href="${getStoreWebsiteUrl(
            store
        )}" target="_blank" style="color: #2b90ed; font-size: 14px; font-weight: bold">${store.name
            }</a>`);

        infoWindow.open(marker.getMap(), marker);
    };
    const removeSelectedMarker = () => {
        infoWindow.close();
        storesWithMarker.current?.forEach(({ store, marker }) => {
            marker.setZIndex(undefined);
            marker.setIcon({
                url:
                    store.discountRate >= 80 ? MARKER_ICON.promotion : MARKER_ICON.normal,
                labelOrigin: new window.google.maps.Point(10, -10),
            });
            marker.setLabel({ text: store.name, className: "marker-label" });
        });
    };

    const markerClusterRender = ({ count, position }, stats) => {
        // change color if this cluster has more markers than the mean cluster
        const color =
            count > Math.max(10, stats.clusters.markers.mean)
                ? "#294986"
                : "#4b84f3";
        // create svg url with fill color
        const svg = window.btoa(`
      <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity=".8" r="70" />
        <circle cx="120" cy="120" opacity=".5" r="90" />
        <circle cx="120" cy="120" opacity=".3" r="110" />
      </svg>`);
        // create marker using svg icon
        return new window.google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new window.google.maps.Size(45, 45),
            },
            label: {
                text: String(count),
                color: "rgba(255,255,255,0.9)",
                fontSize: "12px",
            },
            title: `Cluster of ${count} markers`,
            // adjust zIndex to be above other markers
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
        });
    };

    const setMarker = (newStores, runFitBounds) => {
        storesWithMarker.current = storesAddMarker(newStores);
        if (markerCluster.current) markerCluster.current.clearMarkers();
        const renderer = new DefaultRenderer();
        renderer.render = markerClusterRender;
        markerCluster.current = new MarkerClusterer({
            map,
            markers: storesWithMarker.current.map(({ marker }) => marker),
            renderer,
            onClusterClick: (event, cluster, map) => {
                dispatch(mainSlice.actions.selectStore(null));
                map.fitBounds(cluster.bounds);
            },
        });

        if (markerCluster.current.markers.length > 0 && runFitBounds) {
            const bounds = getBoundsByLatLngs(markerCluster.current.markers.map(marker => marker.getPosition()));
            map.setZoom(15);
            map.fitBounds(bounds);
        }
    };
    return {
        setMarker,
        setSelectedMarker,
        removeSelectedMarker
    }
}