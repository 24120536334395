export const getBoundsByLatLngs = (latLngs) => {
    const bounds = new window.google.maps.LatLngBounds();
    latLngs.forEach((latLng) => {
        bounds.extend(latLng);
    });

    window.google.maps.LatLngBounds.prototype.customContains = function ({ lat, lng }) {
        const { north, south, west, east } = this.toJSON();
        return (north > lat) !== (south > lat) && (west > lng) !== (east > lng);
    }
    return bounds;
}