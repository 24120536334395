import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Pages from "../Common/Pages";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Stores(props) {
  const { t } = useTranslation("store");
  const userData = useSelector((state) => state.auth.userData);
  const [stores, setStores] = useState([]);
  const [page, setPageState] = useState(1);
  const rowSize = 10;
  const lastPage =
    stores.length === 0
      ? 1
      : Math.floor(stores.length / rowSize) +
        (stores.length % rowSize === 0 ? 0 : 1);
  const offset = (page - 1) * rowSize;

  useEffect(() => {
    axios
      .get(`/approved-stores?userId=${userData.id}&sort=id,asc&size=1000`)
      .then((res) => {
        if (res.status === 200 && res.data.length > 0) {
          setStores(res.data?.reverse());
        }
      });
  }, []);

  const setPage = (n) => {
    if (n < 1) setPageState(1);
    else if (n > lastPage) setPageState(lastPage);
    else setPageState(n);
  };

  return (
    <div className="container-max-640 py-3">
      <h2 className="text-center mb-4">{t("title")}</h2>

      <div className={"d-flex mt-5"}>
        <Link className={"btn btn-primary w-100"} to="/join-store">
          {t("add")}
        </Link>
      </div>

      <section className="mt-5">
        <h5 className="">{t("myStoreInfo")}</h5>
        {stores.slice(offset, offset + rowSize).map((store, index) => (
          <div className="border rounded p-2 mb-2" key={index}>
            <ul>
              {store.permittedAt === null && (
                <li className="p-1 text-center m-2">
                  <span style={{ color: "red" }}>{t("unapproved")}</span>
                </li>
              )}

              <li className="p-1">
                <span>{t("labels.storeName", { ns: "commons" })} : </span>
                <span>{store.name}</span>
              </li>
              <li className="p-1">
                <span>{t("labels.storeAddress", { ns: "commons" })} : </span>
                <span>
                  {store.address} {store.addressEtc}
                </span>
              </li>
              <li className="p-1">
                <span>
                  {t("labels.storeRegistrationDate", { ns: "commons" })} :{" "}
                </span>
                <span>{moment(store.createdAt).format("YYYY-MM-DD")}</span>
              </li>
              <li className="p-1">
                <span>{t("labels.discountRate", { ns: "commons" })} : </span>
                <span>{store.discountRate}%</span>
              </li>
              <li className="p-1 text-center m-2">
                <Link
                  className="btn btn-sm btn-dark"
                  to={`/stores/${store.id}`}
                >
                  {t("editStore")}
                </Link>
              </li>
            </ul>
          </div>
        ))}
        {stores.length === 0 && (
          <div className="border rounded p-4 mb-2">
            <ul>
              <li className="p-1 text-center">
                {t("notExistStore-1")}
                <br />
                {t("notExistStore-2")}
              </li>
              <li className="p-1 text-center mt-2">
                <Link className="btn btn-sm btn-dark" to="/join-store">
                  {t("registStore")}
                </Link>
              </li>
            </ul>
          </div>
        )}
        {stores.length >= rowSize && (
          <Pages
            page={page}
            lastPage={lastPage}
            setPage={setPage}
            numberOfPage={5}
          />
        )}
      </section>
    </div>
  );
}

export default Stores;
