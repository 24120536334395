import { useTranslation } from "react-i18next";

function JoinStoreStepThree(props) {
  const { t } = useTranslation("store");
  const pushTag = (tag) => {
    if (props.tags.length >= 20) {
      alert(
        t("tagMaxCount", {
          ns: "inputValidation",
          count: 20,
        })
      );
      return;
    }
    if (tag === "") {
      return;
    }
    if (props.tags.includes(tag)) {
      alert(
        t("duplicateTag", {
          ns: "inputValidation",
        })
      );
      return;
    }
    const newTags = [...props.tags];
    newTags.push(tag);
    props.setTags(newTags);
  };

  const removeTag = (tag) => {
    const newTags = [...props.tags];
    const index = newTags.indexOf(tag);
    if (index > -1) {
      newTags.splice(index, 1);
    }
    props.setTags(newTags);
  };

  // const [agreeAll, setAgreeAll] = useState(false);
  // const onAgreeAll = () => {
  //   props.agreePrivacy.setValue(!agreeAll);
  //   props.agreeAgeOverFourTeen.setValue(!agreeAll);
  //   props.agreeEvent.setValue(!agreeAll);
  // };

  // useEffect(() => {
  //   const isAllChecked = () => {
  //     return (
  //       props.agreePrivacy.attr.checked &&
  //       props.agreeAgeOverFourTeen.attr.checked &&
  //       props.agreeEvent.attr.checked
  //     );
  //   };
  //   setAgreeAll(isAllChecked());
  // }, [
  //   props.agreeAgeOverFourTeen.attr.checked,
  //   props.agreeEvent.attr.checked,
  //   props.agreePrivacy.attr.checked,
  // ]);
  return (
    <div className="info">
      <div className={"mt-3"}>
        <label>{t("labels.discountRate", { ns: "commons" })}</label>
        <span className={"star"}>*</span>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="number"
            className={"form-control"}
            {...props.discountRate.attr}
            placeholder={t("join.placeholder.discountRate")}
          />
        </div>
        <span style={{ color: "grey", fontSize: "0.8em" }}>
          ⓘ {t("join.infoMsg.discountRate")}
        </span>
        {props.discountRate.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.discountRate.error.msg}
          </span>
        )}
      </div>
      <div className={"mt-3"}>
        <label>{t("labels.storePromotionalSite", { ns: "commons" })}</label>
        <div className={"d-flex align-items-center mt-2"}>
          <textarea
            rows="3"
            style={{ width: "100%" }}
            className={"form-control"}
            {...props.website.attr}
            placeholder={t("join.placeholder.website")}
          />
        </div>
      </div>
      {props.website.error.status && (
        <span style={{ color: "red", fontSize: "0.8em" }}>
          {props.website.error.msg}
        </span>
      )}

      <div className={"mt-3"}>
        <label>{t("labels.tag", { ns: "commons" })}</label>

        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            id="tagInput"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                pushTag(e.target.value.replace(/ /g, ""));
                e.target.value = "";
              }
            }}
          />
          <button
            className="btn-sm btn-dark"
            type="button"
            onClick={() => {
              const tagInput = document.getElementById("tagInput");
              pushTag(tagInput.value.replace(/ /g, ""));
              tagInput.value = "";
            }}
          >
            {t("btns.input", { ns: "commons" })}
          </button>
        </div>
        <div className="mt-2 mb-2 d-flex flex-wrap">
          {props.tags.map((tag, index) => (
            <div className="p-1 bg-light m-1 rounded" key={index}>
              {`#${tag}`}
              <span
                className="p-1"
                onClick={() => removeTag(tag)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/icon/close.svg`}
                  alt=""
                />
              </span>
            </div>
          ))}
        </div>
        <div style={{ color: "gray", fontSize: "0.8em" }}>
          ⓘ {t("join.infoMsg.tag")}
        </div>
      </div>
      <div className={"d-flex mt-5"}>
        <button
          type="button"
          className={"btn btn-secondary flex-shrink-1 me-1"}
          onClick={() => props.setStep(1)}
        >
          {t("btns.prev", { ns: "commons" })}
        </button>
        <button
          type="button"
          className={"btn btn-primary flex-fill"}
          onClick={props.handleSubmit}
          disabled={props.progress}
        >
          {t("btns.ok", { ns: "commons" })}
        </button>
      </div>
    </div>
  );
}
export default JoinStoreStepThree;
