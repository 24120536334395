import { useTranslation } from "react-i18next";

function JoinDealerStepTwo(props) {
  const { t } = useTranslation("dealer");

  return (
    <section>
      <div className={""}>
        <h5 className={"text-center"}>{t("join.step-2.title")}</h5>
        <div className={"mt-3"}>
          <label>{t("labels.name", { ns: "commons" })}</label>
          <span className="text-danger">*</span>
          <div className={"d-flex align-items-center mt-2"}>
            <input
              type="text"
              className={"form-control"}
              {...props.name.attr}
            />
          </div>
        </div>
        {props.name.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.name.error.msg}
          </span>
        )}
        <div className={"mt-3"}>
          <label>{t("labels.walletAddress", { ns: "commons" })}</label>
          <span className="text-danger">*</span>
          <div className={"d-flex align-items-center mt-2"}>
            <input
              type="text"
              className={"form-control"}
              {...props.masterbankWalletAddr.attr}
            />
          </div>
        </div>
        {props.masterbankWalletAddr.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.masterbankWalletAddr.error.msg}
          </span>
        )}
        <div className={"mt-3"}>
          <label>{t("labels.phone", { ns: "commons" })}</label>
          <span className="text-danger">*</span>
          <div className={"d-flex align-items-center mt-2"}>
            <input
              type="text"
              className={"form-control"}
              {...props.mobile.attr}
            />
          </div>
        </div>
        {props.mobile.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.mobile.error.msg}
          </span>
        )}
        <div className={"mt-3"}>
          <label>{t("labels.referralCode", { ns: "commons" })}</label>
          <div className={"d-flex align-items-center mt-2"}>
            <input
              type="text"
              className={"form-control"}
              {...props.referralCode.attr}
            />
          </div>
        </div>
        {props.referralCode.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.referralCode.error.msg}
          </span>
        )}
        <button
          type="button"
          onClick={props.handleSubmit}
          className={"btn btn-primary w-100 mt-5"}
          disabled={props.progress}
        >
          {t("btns.next", { ns: "commons" })}
        </button>
      </div>
    </section>
  );
}

export default JoinDealerStepTwo;
