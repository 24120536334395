import { useState } from "react";
import styled from "styled-components";
import { copyWithToast } from "../../helpers/copyWithToast";

const Modal = styled.div`
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;
const Flex = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`;
const ModalContainer = styled.div`
  background-color: white;
  border-radius: 30px;
  width: 90%;
  height: 330px;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-weight: bold;
  flex-basis: content;
`;
const CloseBtn = styled.div`
  cursor: pointer;
`;
const BtnSection = styled.div`
  display: flex;
  flex-basis: 50%;
  align-items: center;
`;
const CopyBtnSection = styled(BtnSection)`
  border-bottom: 1px solid #eee;
`;
const Btn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  flex-basis: 25%;
  cursor: pointer;
`;
const BtnLabel = styled.div`
  margin-top: 5px;
`;

export const ShareModal = (props) => {
  return (
    props.shareUrl && (
      <Modal isShow>
        <Flex>
          <ModalContainer>
            <Header>
              <div>공유하기</div>
              <CloseBtn
                onClick={() => {
                  props.closeCallback();
                }}
              >
                <img src="/img/icon/share_close.svg" alt="copy" />
              </CloseBtn>
            </Header>
            <CopyBtnSection>
              <Btn
                onClick={() => {
                  copyWithToast(props.shareUrl);
                  props.closeCallback();
                }}
              >
                <div>
                  <img src="/img/icon/share_copy_btn.svg" alt="copy" />
                </div>
                <BtnLabel>링크 복사</BtnLabel>
              </Btn>
            </CopyBtnSection>
            <BtnSection>
              <Btn
                onClick={() => {
                  window.open(
                    `http://www.facebook.com/sharer/sharer.php?u=${props.shareUrl}`
                  );
                  props.closeCallback();
                }}
              >
                <div>
                  <img src="/img/icon/share_facebook.svg" alt="copy" />
                </div>
                <BtnLabel>Facebook</BtnLabel>
              </Btn>
              <Btn
                onClick={() => {
                  window.open(
                    `https://twitter.com/intent/tweet?&url=${props.shareUrl}`
                  );
                  props.closeCallback();
                }}
              >
                <div>
                  <img src="/img/icon/share_twitter.svg" alt="copy" />
                </div>
                <BtnLabel>Twitter</BtnLabel>
              </Btn>
            </BtnSection>
          </ModalContainer>
        </Flex>
      </Modal>
    )
  );
};
