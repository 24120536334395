import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Main from "./components/Main/Main";
import Header from './components/Header/Header';
import ServiceIntro from './components/ServiceIntro/ServiceIntro';
import JoinStore from "./components/Store/JoinStore/JoinStore";
import Stores from "./components/Store/Stores";
import DetailStore from "./components/Store/DetailStore";
import HoldingStore from './components/Store/HoldingStore';
import Dealer from './components/Dealer/Dealer';
import JoinDealer from "./components/Dealer/JoinDealer/JoinDealer";
import HoldingDealer from './components/Dealer/HoldingDealer';
import MyInfo from "./components/MyInfo/MyInfo";

import AuthRoute from './routes/AuthRoute';
import { useDispatch } from 'react-redux'
import keycloak from './keycloak'
import { loadUserData } from './helpers/loadUserData';
import { useExternalScript } from './hooks/useExternalScript';
import ShareUrlRoute from './routes/ShareUrlRoute';
import './app.scss';

function App() {

  loadUserData(useDispatch())
  const scriptState = useExternalScript();

  return (
    <Router>
      <section >
        <Header login={keycloak.login} register={keycloak.register} />
        <Switch>
          <ShareUrlRoute exact path="/target/:store" />
          {scriptState === 'ready' && <Route exact path="/" component={Main} />}
          <Route exact path="/service-intro" component={ServiceIntro} />

          <AuthRoute exact path="/join-store" component={JoinStore} />
          <AuthRoute exact path="/stores" component={Stores} />
          <AuthRoute exact path="/stores/:storeId" component={DetailStore} />
          <AuthRoute exact path="/holding-store" component={HoldingStore} />
          <AuthRoute exact path="/dealer" component={Dealer} />
          <AuthRoute exact path="/join-dealer" component={JoinDealer} />
          <AuthRoute exact path="/holding-dealer" component={HoldingDealer} />
          <AuthRoute exact path="/my-info" component={MyInfo} />
        </Switch>
      </section>
      <Toaster />
    </Router>
  );
}

export default App;
