import { useState } from "react";
import SearchAddressByGoogleMap from "./SearchAddressByGoogleMap";
import { checkReferralCode } from "../../../helpers/checkReferralCode";
import { useTranslation } from "react-i18next";
import { STORE_KINDS } from "../../../constant/storeKinds";

function JoinStoreStepOne(props) {
  const { t, i18n } = useTranslation("store");
  const [isOnSearchAddress, setIsOnSearchAddress] = useState(false);
  const openPost = () => {
    if (i18n.language.startsWith("ko")) {
      new window.daum.Postcode({
        oncomplete: (data) => {
          let addr = "";
          if (data.userSelectedType === "R") {
            addr = data.roadAddress;
          } else {
            addr = data.jibunAddress;
          }
          props.address.set(addr);
          props.setCountry("KR");
          searchAddressToCoordinate(addr);
        },
      }).open();
    } else {
      setIsOnSearchAddress(true);
    }
  };
  const searchAddressToCoordinate = (address) => {
    if (window.naver.maps.Service) {
      window.naver.maps.Service.geocode(
        {
          query: address,
        },
        (status, response) => {
          if (status === window.naver.maps.Service.Status.OK) {
            const item = response.v2.addresses[0];
            props.setCoordinate({ lat: item.y, lng: item.x });
          }
        }
      );
    }
  };

  const goToNext = async () => {
    const form = document.getElementById("storeForm");
    if (props.name.error.status) {
      props.name.validator(props.name.attr.value);
      form.name.focus();
      return false;
    }
    if (props.kind.error.status) {
      props.kind.validator(props.kind.attr.value);
      form.kind.focus();

      return false;
    }

    if (props.address.error.status) {
      props.address.validator(props.address.attr.value);
      form.address.focus();

      return false;
    }
    if (props.addressEtc.error.status) {
      props.addressEtc.validator(props.addressEtc.attr.value);
      form.addressEtc.focus();

      return false;
    }
    if (props.phone.error.status) {
      props.phone.validator(props.phone.attr.value);
      form.phone.focus();

      return false;
    }
    if (props.referralCode.error.status) {
      props.referralCode.validator(props.referralCode.attr.value);
      form.referralCode.focus();

      return false;
    }

    if (
      props.referralCode.attr.value !== "" &&
      !(await checkReferralCode(props.referralCode.attr.value))
    ) {
      props.referralCode.setError({
        status: true,
        msg: t("errors.invalidReferralCode", { ns: "commons" }),
      });

      return false;
    }

    props.setStep(1);
  };
  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <label>{t("labels.storeName", { ns: "commons" })}</label>
            <span className="text-danger">*</span>
          </div>
          <span className="text-danger fw-lighter">
            {t("join.requiredMsg")}
          </span>
        </div>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            {...props.name.attr}
            placeholder={t("join.placeholder.storeName")}
          />
        </div>
        {props.name.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.name.error.msg}
          </span>
        )}
      </div>
      <div className={"mt-3"}>
        <label>{t("labels.storeKind", { ns: "commons" })}</label>
        <span className="text-danger">*</span>
        <div className="mt-2">
          <select className={"form-control"} {...props.kind.attr}>
            <option value="">{t("labels.select", { ns: "commons" })}</option>
            {STORE_KINDS.map((kind) => (
              <option key={kind} value={kind}>
                {t(`storeKinds.${kind}`, { ns: "commons" })}
              </option>
            ))}
          </select>
        </div>
        {props.kind.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.kind.error.msg}
          </span>
        )}
      </div>
      <div className={"mt-3"}>
        <label>{t("labels.storeAddress", { ns: "commons" })}</label>
        <span className="text-danger">*</span>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control "}
            {...props.address.attr}
            onClick={openPost}
            placeholder={t("join.placeholder.address")}
            readOnly
          />
          <button
            type="button"
            className="btn btn-outline-dark ms-2 w-25"
            onClick={openPost}
          >
            {t("join.step-1.searchAddress")}
          </button>
        </div>
        {props.address.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.address.error.msg}
          </span>
        )}
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            {...props.addressEtc.attr}
            placeholder={t("join.placeholder.addressEtc")}
          />
        </div>
        {props.addressEtc.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.addressEtc.error.msg}
          </span>
        )}
      </div>
      <div className={"mt-3"}>
        <label>{t("labels.storePhone", { ns: "commons" })}</label>
        <span className="text-danger">*</span>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            {...props.phone.attr}
            placeholder="02-123-4567"
          />
        </div>
        {props.phone.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.phone.error.msg}
          </span>
        )}
      </div>
      <div className={"mt-3"}>
        <label>{t("labels.dealerCode", { ns: "commons" })}</label>
        <span className="text-danger">*</span>
        <div className={"d-flex align-items-center mt-2"}>
          <input
            type="text"
            className={"form-control"}
            {...props.referralCode.attr}
            placeholder={t("join.placeholder.dealerCode")}
          />
        </div>
        <div style={{ color: "gray", fontSize: "0.8em" }}>
          ⓘ {t("join.infoMsg.dealerCode")}
        </div>
        {props.referralCode.error.status && (
          <span style={{ color: "red", fontSize: "0.8em" }}>
            {props.referralCode.error.msg}
          </span>
        )}
      </div>
      <div className={"d-flex mt-5"}>
        <button
          type="button"
          className={"btn btn-primary flex-fill"}
          onClick={goToNext}
        >
          {t("btns.next", { ns: "commons" })}
        </button>
      </div>

      {isOnSearchAddress && (
        <SearchAddressByGoogleMap
          setIsOnSearchAddress={setIsOnSearchAddress}
          setAddress={props.address.set}
          setCoordinate={props.setCoordinate}
          setCountry={props.setCountry}
        />
      )}
    </>
  );
}
export default JoinStoreStepOne;
